import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FOCUSED_NODE_ID,
  TOP_SCROLL_INDENT,
  MAX_SCROLL_RETRIES,
  RETRY_SCROLL_WAITING_TIME_IN_MS,
  SCROLL_INITIAL_WAITING_TIME_IN_MS,
} from "../constants";

export const useScrollToAnchor = () => {
  const [searchParams] = useSearchParams();
  const nodeId = useMemo(
    () => searchParams.get(FOCUSED_NODE_ID) || "",
    [searchParams],
  );

  const getElement = () => document.getElementById(nodeId);

  useEffect(() => {
    // It is necessary to wait for the components to render. So we wait and retry, if the element is still not rendered.
    // Max. waiting time: 2650ms (initial 150ms and then 50 retries each with 50ms of waiting)

    let retries = 0;
    let timeoutId: NodeJS.Timeout;

    const scroll = () => {
      retries += 1;
      if (retries > MAX_SCROLL_RETRIES) {
        return;
      }

      const element = getElement();

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: elementPosition - TOP_SCROLL_INDENT,
          behavior: "smooth",
        });

        return;
      }

      timeoutId = setTimeout(scroll, RETRY_SCROLL_WAITING_TIME_IN_MS);
    };

    timeoutId = setTimeout(scroll, SCROLL_INITIAL_WAITING_TIME_IN_MS);

    return () => clearTimeout(timeoutId);
  }, [nodeId]);
};
