import cloneDeep from "lodash.clonedeep";
import { TGenericData } from "../../../interfaces";
import {
  STORAGE_KEY_SEPARATOR,
  STORAGE_KEY_ARRAY_INDICATOR,
} from "../../../constants";
import { ISetDynamicData } from "../store/PCPSlice";

/**
 * Sets the storage data in the given original state based on the provided storage key and data.
 * If the storage key has an array indicator .[] , the data will be pushed to the array.
 * E.g.,
 * 1) update array storageKey: "persons.sites.[]", data: "YES"
 *   { persons: { sites: [1, 2, 3] } } => { sites: [ 1, 2, 3, 'YES' ] }
 * 2) override array storageKey: "persons.sites", data: [11, 22, 33]
 *   { persons: { sites: [1, 2, 3] } } => { persons: { sites: [ 11, 22, 33 ] }}
 *
 * If the storage key is an object, the data will be set as the value of the key.
 * 1) storageKey: "persons.isSiteAvailable", data: "YES"
 *   { persons: { sites: [1, 2, 3] } } => { sites: [ 1, 2, 3 ], isSiteAvailable: 'YES' }
 * 2) storageKey: "persons.sites", data: "YES"
 *   object: // { persons: { sites: { 0: 1, 1: 2, 2: 3 } } } => { persons: { sites: 'YES' }}
 *   string: // { persons: { sites: 'NO' } } => { persons: { sites: 'YES' }}
 *
 * Returns the updated state.
 *
 * @param originalState - The original state object.
 * @param storageKey - The key used to access the storage data.
 * @param data - The data to be stored.
 * @returns The updated state object.
 */

export const setStorageData = <T>(
  originalState: TGenericData,
  { storageKey, data }: ISetDynamicData,
): T => {
  const state = cloneDeep(originalState);
  const pathArr = storageKey.split(STORAGE_KEY_SEPARATOR);
  const lastKeyIndex = pathArr.length - 1;

  pathArr.reduce(
    (currentState: TGenericData, key: string, index: number): TGenericData => {
      if (key === STORAGE_KEY_ARRAY_INDICATOR && index === lastKeyIndex) {
        if (Array.isArray(currentState)) {
          // data can be an array or a single value
          const itemsToPush = Array.isArray(data) ? data : [data];
          currentState.push(...itemsToPush);
        }

        return currentState;
      }

      // if we're at the last key (and it's not the array indicator)
      if (index === lastKeyIndex) {
        // set the data at the key in the current state
        currentState[key] = data;
      } else {
        currentState[key] =
          currentState[key] ||
          (pathArr[index + 1] === STORAGE_KEY_ARRAY_INDICATOR ? [] : {});
      }
      // go deeper into the state object
      return currentState[key] as TGenericData;
    },
    state,
  );

  return state as T;
};
