import { PayloadAction } from "@reduxjs/toolkit";
import { AUTH_SAGA_ACTION_TYPES } from "../constants";
import { ILogin } from "../interfaces/ILogin";
import { ILogout } from "../interfaces/ILogout";

export const loginAction = (payload: ILogin): PayloadAction<ILogin> => ({
  type: AUTH_SAGA_ACTION_TYPES.LOGIN,
  payload,
});

export const logoutAction = (payload: ILogout): PayloadAction<ILogout> => ({
  type: AUTH_SAGA_ACTION_TYPES.LOGOUT,
  payload,
});
