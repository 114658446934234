import {
  IEntityInput,
  IGraphQlRequest,
  IPrepareGqlRelation,
  MDM_ACTIONS,
} from "../../interfaces";

const createRelationshipStructure = (
  preparedRelations: Record<string, IPrepareGqlRelation>,
) => {
  const relationships: Record<string, IEntityInput[]> = {};

  Object.entries(preparedRelations ?? {}).forEach(([key, entry]) => {
    const { relationIds: possiblyNotAnArray, previousRelationIds = [] } = entry;

    const relationIds = Array.isArray(possiblyNotAnArray)
      ? possiblyNotAnArray.slice()
      : [possiblyNotAnArray];

    // To delete relations, we need to set and additional action: "delete" flag inside the mutate call for this specific
    // part. To get the necessary data for it, we need to compare the original (previous) ids, with our mutation call.
    // Then we know which data needs to be deleted.
    const relationsToDelete = previousRelationIds.filter(
      (prevRelationId) =>
        prevRelationId && !relationIds.includes(prevRelationId),
    );

    // Generate the items for relations we want to mutate/add to the entity
    relationships[key] = relationIds.filter(Boolean).map((id) => ({
      id,
    }));

    // Map of the entities we need to remove, an add the action: "delete" flag for them inside the mutation.
    // Combined with the step before, the structure for the mutation call is complete.
    relationships[key] = relationships[key].concat(
      relationsToDelete?.map((id) => ({
        id,
        action: MDM_ACTIONS.delete,
      })) ?? [],
    );
  });

  return relationships;
};

/**
 * This function creates MDM specific entity-request-configs. Unfortunately we need this, because of the complicated
 * MDM data structure. Hopefully gets replaced in the future, if we have a simpler data structure in the backend then.
 *
 * @returns {Object} MDM adjusted request config for updating relations.
 * @param {Object} configuration - GqlRequest with possible relations defined. Those params defines the data, that should be processed to MDM.
 * */
export const createMDMtoGQLAdjustedRequest = (
  configuration: IGraphQlRequest,
): IGraphQlRequest => {
  const {
    node,
    variables: nonRelationalVariables,
    relationVariables,
  } = configuration;

  if (!relationVariables) {
    return configuration;
  }

  const adjustedMDMRelationVariables =
    createRelationshipStructure(relationVariables);

  return {
    node,
    variables: {
      ...nonRelationalVariables,
      ...adjustedMDMRelationVariables,
    },
  };
};
