import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import { IComponent } from "../../../interfaces";
import { useAppSelector } from "../../../hooks";
import { selectToken } from "../../auth/store/AuthSelectors";
import { initRenderer } from "../../processing-pcp";

interface IUrlManager {
  sharedAPIRequestDataComponents?: IComponent[];
}

export const APIRequestsDataComponent = ({
  sharedAPIRequestDataComponents = [],
}: IUrlManager) => {
  const accessToken = useAppSelector(selectToken);

  if (!accessToken) {
    return null;
  }

  return (
    <>
      {sharedAPIRequestDataComponents.map((requestComponent) => {
        return (
          <Fragment key={uuidv4()}>{initRenderer(requestComponent)}</Fragment>
        );
      })}
    </>
  );
};
