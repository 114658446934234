import { put, takeLatest, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationResult } from "@azure/msal-browser";
import { AUTH_SAGA_ACTION_TYPES } from "../constants";
import { login } from "../helpers/login";
import { ILogout } from "../interfaces/ILogout";
import { logout } from "../helpers/logout";
import { ILogin } from "../interfaces/ILogin";
import { authActions } from "./AuthSlice";

function* loginUser({ payload }: PayloadAction<ILogin>) {
  try {
    const { accessToken, account }: AuthenticationResult = yield call(
      login,
      payload,
    );
    yield put(authActions.setAccount({ account }));
    yield put(authActions.setAccessToken({ accessToken }));
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call(() => console.log(error));
    yield put(authActions.setError({ error }));
    // If silent login fails, redirect to MS login page
    yield call(() =>
      payload.msalInstance.loginRedirect({
        scopes: payload.scopes,
        prompt: "select_account",
      }),
    );
  }
}

function* logoutUser({ payload }: PayloadAction<ILogout>) {
  try {
    yield call(logout, payload);
    yield put(authActions.setAccessToken({ accessToken: undefined }));
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call(() => console.log(error));
    yield put(authActions.setError({ error }));
  }
}

export function* AuthSagas() {
  yield takeLatest(AUTH_SAGA_ACTION_TYPES.LOGIN, loginUser);
  yield takeLatest(AUTH_SAGA_ACTION_TYPES.LOGOUT, logoutUser);
}
