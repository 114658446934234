import { Grid, Box, Stack, Container } from "@mui/material";
import { ProtectedPaths, SignIn, UnprotectedPaths } from "./auth";
import { APIRequestDataComponent } from "./pcd-request-data/components/APIRequestDataComponent";
import { ExecuteCommandComponent } from "./pcp/components/ExecuteCommandComponent";

export const PCP_PAGES = {
  SignIn: "SignIn",
};

export const InternalComponentMap = {
  ProtectedPaths,
  UnprotectedPaths,
  SignIn,
  Grid,
  Box,
  Stack,
  Container,
  APIRequestDataComponent,
  ExecuteCommandComponent,
};
