import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useAppSelector } from "../../../hooks";
import { selectConfig } from "../../pcp/store/PCPSelectors";

export const SignIn = () => {
  const config = useAppSelector(selectConfig);
  const { instance, accounts, inProgress } = useMsal();
  // todo: add functionality for use of multiple accounts
  // todo: make signign loginredirect in sagas too
  const account = accounts[0];

  useEffect(() => {
    const handleLogin = async () => {
      try {
        if (!account && inProgress === InteractionStatus.None) {
          await instance.loginRedirect({
            scopes: config.msal.scopes,
            prompt: "select_account",
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    handleLogin();
  }, [instance, account, inProgress]);

  return null;
};
