import axios, { HttpStatusCode } from "axios";
import { IPCD } from "../../../interfaces";
import { axiosClient } from "../../../api";
import { ILoadPCD } from "../interfaces/ILoadPCD";

export const getPCD = async ({
  pcdEndpoint,
  pcdId,
}: ILoadPCD): Promise<IPCD> => {
  // For local testing/debugging purpose only! If you add new features or anything, use paul-pcds repo.
  // return paulClientsPCD;

  const { data: dynamicPCD, status } = await axiosClient.post(pcdEndpoint, {
    id: pcdId,
  });

  if (status !== HttpStatusCode.Ok) {
    throw new Error("Could not retrieve PCD from backend!");
  }

  axios.defaults.baseURL = dynamicPCD.config.apiBaseUrl;

  return dynamicPCD;
};
