import { ISource } from "./IParams";

export enum CONDITION_OPERATION {
  EQUAL = "===",
  NOT_EQUAL = "!==",
  AND = "&&",
  OR = "||",
  GREATER = ">",
  LESS = "<",
  GREATER_OR_EQUAL = ">=",
  LESS_OR_EQUAL = "<=",
  INCLUDES = "includes",
  INCLUDES_REVERSED = "includes reversed",
  NOT_INCLUDES = "not includes",
  UNIQUE = "unique",
}

export interface IConditionValue {
  value?: unknown;
  dataSource?: ISource;
  translatable?: boolean;
}

export type TOperand = IConditionBody | IConditionValue;

export interface IConditionBody {
  operation: CONDITION_OPERATION;
  operands: TOperand[];
  pathToIncludesValue?: string;
}

export interface IDataToApply {
  [key: string]: Record<string, unknown>;
}

export interface IConditionDefinition {
  conditions: IConditionBody;
  dataToApply: IDataToApply;
}

export interface IConditionObjectProps {
  [key: string]: IConditionDefinition[] | IConditionProps;
}

export type IConditionProps = IConditionObjectProps | IConditionDefinition[];
