import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";
import { TGenericData } from "../interfaces";

export const getDifference = (
  compareValue: TGenericData,
  withValue: TGenericData,
) => {
  const compare1 = Object.entries(compareValue);
  const compare2 = Object.entries(withValue);

  return Object.fromEntries(differenceWith(compare1, compare2, isEqual));
};
