import { getDataByPath } from "@paul/paul-components-collector-package";
import { TGenericData } from "../interfaces";

export const doesInclude = (
  data: TGenericData[],
  pathToIncludesValue: string,
  shouldInclude: unknown,
) =>
  data.some(
    (item) => getDataByPath(pathToIncludesValue, item) === shouldInclude,
  );
