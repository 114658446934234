import { ICommandContext, TCommand, TPrepareCommand } from "../../interfaces";
import { logoutAction } from "../../features/auth/store/AuthSagaActions";
import { isLogoutCommand } from "./guards";

// Doc: https://actaqua.atlassian.net/wiki/x/AYDHh
export const logout: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) => () => {
    if (!isLogoutCommand(command)) {
      console.warn(
        "Please check the logout properties. Something seems to be wrong.",
      );

      return;
    }

    commandContext.dispatch(
      logoutAction({ msalInstance: commandContext.msal.instance }),
    );
  };
