import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "./hooks";

export const useCommandContext = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const msal = useMsal();
  const urlParams = useParams();

  return useMemo(() => ({ navigate, dispatch, msal, urlParams }), [urlParams]);
};
