import { ComponentProps, useEffect } from "react";
import {
  AuthenticatedTemplate as MSALAuthenticated,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Outlet, useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { NAVIGATION } from "../../../constants";
import { useAppSelector } from "../../../hooks";
import { selectIsAuthInitializing } from "../store/AuthSelectors";
import { LoadingIndicator } from "../../processing-pcp";

export const ProtectedPaths = (
  props: ComponentProps<typeof MSALAuthenticated>,
) => {
  const isAuthenticated = useIsAuthenticated();
  const isAuthInitializing = useAppSelector(selectIsAuthInitializing);
  const { inProgress } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      navigate(NAVIGATION.signIn);
    }
  }, [isAuthenticated, inProgress]);

  if (isAuthInitializing) {
    return <LoadingIndicator loadingDesc="Check authentication..." />;
  }

  return (
    <MSALAuthenticated {...props}>
      <Outlet />
    </MSALAuthenticated>
  );
};
