import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountInfo } from "@azure/msal-browser";
import { ISetError } from "../../../interfaces";

interface ISetAccessToken {
  readonly accessToken?: string;
}
interface ISetAccount {
  readonly account?: AccountInfo;
}

interface ISetIsAuthInterceptorInitialized {
  readonly isAuthInterceptorInitialized: boolean;
}

interface IAuthState {
  account?: AccountInfo;
  isAuthInterceptorInitialized: boolean;
  isAuthInitializing: boolean;
  accessToken?: string;
  error?: unknown;
}

const initialState: IAuthState = {
  isAuthInterceptorInitialized: false,
  isAuthInitializing: true,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<ISetAccount>) => {
      state.account = action.payload.account;
    },
    // todo: discuss and implement (generic) error functionality: Maybe don't use set error on every slice separately
    setError: (state, action: PayloadAction<ISetError>) => {
      const { error } = action.payload;

      state.error = error;
    },
    setAccessToken: (state, action: PayloadAction<ISetAccessToken>) => {
      const { accessToken } = action.payload;

      state.accessToken = accessToken;
      state.isAuthInitializing = false;
      state.error = undefined;
    },
    setIsAuthInterceptorInitialized: (
      state,
      action: PayloadAction<ISetIsAuthInterceptorInitialized>,
    ) => {
      const { isAuthInterceptorInitialized } = action.payload;

      state.isAuthInterceptorInitialized = isAuthInterceptorInitialized;
    },
  },
});

export const authActions = AuthSlice.actions;

export const authReducer = AuthSlice.reducer;
