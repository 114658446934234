import { getDataByPath } from "@paul/paul-components-collector-package";
import { IEventContextData, IReduceArrayByKey } from "../../interfaces";
import { createTransformedData } from "./createTransformedData";

// Doc: https://actaqua.atlassian.net/wiki/x/GACFhQ
export const reduceArrayByKey = (
  toTransform: Record<string, unknown>[],
  transformationWay: IReduceArrayByKey,
  eventContextData: IEventContextData,
) =>
  toTransform.reduce((accumulator: unknown[], item) => {
    let dataItem = getDataByPath(transformationWay.mappingPath, item);

    if (transformationWay.transformDataItem) {
      dataItem = createTransformedData(
        dataItem,
        { ...eventContextData, eventDetails: item },
        transformationWay.transformDataItem,
      );
    }

    return transformationWay.spreadDataItem
      ? accumulator.concat(dataItem)
      : accumulator.concat([dataItem]);
  }, []);
