import { useMemo, useRef } from "react";
import { IComponent, TGenericData } from "../interfaces";
import { createDynamicData } from "../helpers/createDynamicData";
import { useContextData } from "./useContextData";
import { getDifference } from "../helpers/getDifference";

/**
 * @description
 * useDynamicProps will process pcd defined dynamicProps and return them in the same structure but with replaced real values
 * like we do it already internally inside commands in combination of params with the function createDynamicData
 * @see createDynamicData() for more
 */
export const useDynamicProps = (component: IComponent) => {
  const contextData = useContextData();
  const snapShot = useRef<TGenericData>({});
  const { dynamicProps } = component;

  const getDynamicProps = (): TGenericData => {
    if (!dynamicProps) {
      return {};
    }

    const processedProps = createDynamicData(
      dynamicProps.props,
      contextData,
      dynamicProps.params,
    );

    // improve performance and stability by checking if props really changed deeply. Sometimes its unrelated (redux value
    // changed, that are not used for the component, but still can cause unnecessary re-renders) or only object refs
    // changed and not the values itself
    const diff = getDifference(processedProps, snapShot.current);
    const isDifferentToPrevious = Object.keys(diff).length;

    if (isDifferentToPrevious) {
      snapShot.current = { ...snapShot.current, ...diff };
    }

    return snapShot.current;
  };

  const componentDynamicProps = useMemo(() => getDynamicProps(), [contextData]);

  return { componentDynamicProps };
};
