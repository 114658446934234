import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { getEnvironment } from "../../helpers/getEnvironment";

const environment = getEnvironment();

export const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_IO_URL,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/(?:.*\.)?paul-cloud\.de/,
      /^https:\/\/paullive\.com/,
    ],
    replaysSessionSampleRate: ["localhost", "development"].includes(environment)
      ? 1
      : 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
  });

  Sentry.setTag("project", process.env.REACT_APP_PCD_ID || "Unknown");
  Sentry.setTag("version", process.env.REACT_APP_VERSION);
};
