import axios from "axios";
import { ILogin } from "../interfaces/ILogin";

export const login = async ({ scopes, account, msalInstance }: ILogin) => {
  const authData = await msalInstance.acquireTokenSilent({
    scopes,
    account,
  });

  axios.defaults.headers.common.Authorization = `Bearer ${authData.accessToken}`;

  return authData;
};
