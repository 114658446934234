import { TGenericData } from "../../../interfaces";

export const callingFunctionConditionally = async (
  condition: boolean,
  data: TGenericData,
  func?: (data: TGenericData) => void,
  // eslint-disable-next-line consistent-return
) => {
  if (condition && func) {
    return func(data);
  }
};
