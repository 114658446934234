import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "./hooks";
import { selectPCD } from "../features/pcp/store/PCPSelectors";
import { UNINITIALIZED_PCD } from "../features/pcp/constants";
import { loadPCDAction } from "../features/pcp/store/PCPSagaActions";
import { createClient } from "../features/processing-pcp";

export const useInitPCP = () => {
  const dispatch = useAppDispatch();
  const pcd = useAppSelector(selectPCD);
  const [isPCDProcessing, setPCDProcessing] = useState(true); // translate PCD to React Components

  const processedPCD = useMemo(() => {
    if (pcd.version === UNINITIALIZED_PCD) {
      // eslint rule expects to return undefined, otherwise it complains:
      // because of 'consistent-return'
      return;
    }

    const client = createClient(pcd);

    setPCDProcessing(false);

    // eslint-disable-next-line consistent-return
    return client;
  }, [pcd]);

  useEffect(() => {
    const pcdEndpoint = process.env.REACT_APP_PCD_ENDPOINT;
    const pcdId = process.env.REACT_APP_PCD_ID;

    if (!pcdEndpoint || !pcdId) {
      throw new Error(
        "There was no PCD Endpoint or PCD Id defined for this client. Please check " +
          "REACT_APP_PCD_ENDPOINT & REACT_APP_PCD_ID env.",
      );
    }

    if (pcd.version === UNINITIALIZED_PCD) {
      dispatch(loadPCDAction({ pcdEndpoint, pcdId }));
      return;
    }

    axios.defaults.baseURL = pcd.config.apiBaseUrl;
    document.title = pcd.displayName;
  }, [pcd, dispatch]);

  return { pcd, isPCDProcessing, processedPCD };
};
