import { getDataByPath } from "@paul/paul-components-collector-package";
import { ISortArray } from "../../interfaces";

// Doc: https://actaqua.atlassian.net/wiki/x/tQAslw
export const sortArray = (
  toTransform: Record<string, unknown>[],
  transformationWay: ISortArray,
) =>
  [...toTransform].sort((a, b) =>
    getDataByPath<string>(transformationWay.comparisonPath, a).localeCompare(
      getDataByPath<string>(transformationWay.comparisonPath, b),
    ),
  );
