import { ReactNode } from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  IComponent,
  IPage,
  IPCD,
  IProcessedPCD,
  IUserInterface,
  TReactComponent,
  TReactComponentMap,
} from "../../../interfaces";
import { NAVIGATION } from "../../../constants";
import { PCP_PAGES } from "../../InternalComponentMap";
import { ComponentRenderer } from "../../pcp";
import { ProtectedPaths, UnprotectedPaths } from "../../auth";
import { ErrorBoundary } from "../../../components";
import { UrlManager } from "../../urlManager";
import { APIRequestsDataComponent } from "../../pcd-request-data/components/APIRequestsDataComponent";

const signInPage: IPage = {
  path: NAVIGATION.signInPathname,
  content: { component: PCP_PAGES.SignIn },
};

export const createComponent = (
  pcdComponent: IComponent,
  reactComponentMap: TReactComponentMap,
): TReactComponent => {
  const Component = reactComponentMap[pcdComponent.component];

  if (!Component) {
    throw new Error(
      `No React Component found for identifier: ${pcdComponent.component}`,
    );
  }

  return Component;
};

export const initRenderer = (pcdComponent: IComponent): ReactNode => {
  const dynamicChildren: ReactNode[] = [];

  if (pcdComponent?.pcpChildren?.length) {
    pcdComponent.pcpChildren.forEach((child) =>
      dynamicChildren.push(initRenderer(child)),
    );
  }

  return (
    <ComponentRenderer pcdComponent={pcdComponent} key={uuidv4()}>
      {dynamicChildren}
    </ComponentRenderer>
  );
};

const getRouteChildren = (pages: IPage[]): RouteObject[] => {
  return pages.map((page) => ({
    path: page.path,
    element: initRenderer(page.content),
  }));
};

const getRoutes = (UI: IUserInterface, isProtected: boolean): RouteObject[] => {
  const customPages =
    UI.customLayouts?.flatMap((custom) =>
      custom.affectedPaths.map((path) => ({
        path,
        element: initRenderer(custom.layout),
        children: getRouteChildren(
          UI.pages.filter((page) => page.path === path),
        ),
      })),
    ) || [];

  const baseChildren = [
    {
      path: NAVIGATION.default,
      element: UI.baseLayout ? initRenderer(UI.baseLayout) : null,
      children: getRouteChildren(
        isProtected ? UI.pages : [signInPage, ...UI.pages],
      ),
    },
    ...customPages,
  ];

  return [
    {
      path: NAVIGATION.default,
      element: (
        <>
          <APIRequestsDataComponent
            sharedAPIRequestDataComponents={
              UI.sharedAPIRequestDataComponents || []
            }
          />
          <UrlManager>
            {isProtected ? <ProtectedPaths /> : <UnprotectedPaths />}
          </UrlManager>
        </>
      ),
      errorElement: <ErrorBoundary />,
      children: baseChildren,
    },
  ];
};

const initRoot = (pcd: IPCD) => {
  const protectedRoutes = getRoutes(pcd.root.app.protectedUI, true);

  const publicRoutes = getRoutes(pcd.root.app.publicUI, false);

  const routing = protectedRoutes.concat(publicRoutes);

  return createBrowserRouter(routing);
};

// React Client Processor.
export const createClient = (pcd: IPCD): IProcessedPCD => {
  const router = initRoot(pcd);

  return { inflatedClient: <RouterProvider router={router} /> };
};
