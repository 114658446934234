import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "./hooks";
import {
  selectApiStorage,
  selectComponentContext,
} from "../features/pcp/store/PCPSelectors";
import { initialUserContext } from "../constants";
import {
  selectAccount,
  selectToken,
} from "../features/auth/store/AuthSelectors";

export const useContextData = () => {
  const [queryParams] = useSearchParams();
  const accountInfo = useAppSelector(selectAccount) ?? initialUserContext;
  const accessToken = useAppSelector(selectToken);
  const apiStorage = useAppSelector(selectApiStorage);
  const componentContext = useAppSelector(selectComponentContext);
  const urlParams = useParams();

  return useMemo(
    () => ({
      apiStorage,
      componentContext,
      urlParams,
      queryParams,
      userContext: { accountInfo, accessToken },
    }),
    [
      apiStorage,
      componentContext,
      urlParams,
      queryParams,
      accountInfo,
      accessToken,
    ],
  );
};
