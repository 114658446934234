import { PayloadAction } from "@reduxjs/toolkit";
import { PCP_SAGA_ACTION_TYPES } from "../constants";
import { ILoadPCD } from "../interfaces/ILoadPCD";
import { IExecuteApiCall, ISetDataWithCallback } from "../../../interfaces";

export const loadPCDAction = (payload: ILoadPCD): PayloadAction<ILoadPCD> => ({
  type: PCP_SAGA_ACTION_TYPES.LOAD_PCD,
  payload,
});

export const executeAPICall = (
  payload: IExecuteApiCall,
): PayloadAction<IExecuteApiCall> => ({
  type: PCP_SAGA_ACTION_TYPES.EXECUTE_API_CALL,
  payload,
});

export const setDataWithCallback = (
  payload: ISetDataWithCallback,
): PayloadAction<ISetDataWithCallback> => ({
  type: PCP_SAGA_ACTION_TYPES.SET_DATA_WITH_CALLBACK,
  payload,
});
