import { getDataByPath } from "@paul/paul-components-collector-package";
import { IFindInArray } from "../../interfaces";

// Doc: https://actaqua.atlassian.net/wiki/x/AYAxlw
export const findInArray = (
  toTransform: Record<string, unknown>[],
  transformationWay: IFindInArray,
) =>
  toTransform.find(
    (item) =>
      getDataByPath(transformationWay.comparisonPath, item) ===
      transformationWay.comparisonValue,
  );
