import { CacheRequestConfig } from "axios-cache-interceptor";
import {
  ICommandContext,
  IGraphQlRequest,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../interfaces";
import { isAPICallCommand } from "./guards";
import { createDynamicData } from "../createDynamicData";
import { getExtendedCommandContext } from "./getExtendedCommandContext";
import { COMMANDS } from "./Commands";
import { executeAPICall } from "../../features/pcp/store/PCPSagaActions";
import { createMDMtoGQLAdjustedRequest, isGraphQLRequest } from "../gql";

// Doc: https://actaqua.atlassian.net/wiki/x/AYAtkw
export const apiCall: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isAPICallCommand(command)) {
      console.warn(
        "Please check the apiCall properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const {
      params,
      commandCallback,
      onError,
      onSuccess,
      storageKey,
      configuration,
    } = command.commandProps;

    let prepareDynamicAPICall = createDynamicData(
      configuration as Record<string, unknown>,
      extendedCommandContext,
      params,
      eventDetails,
    ) as CacheRequestConfig | IGraphQlRequest;

    if (isGraphQLRequest(prepareDynamicAPICall)) {
      prepareDynamicAPICall = createMDMtoGQLAdjustedRequest(
        prepareDynamicAPICall,
      );
    }

    commandContext.dispatch(
      executeAPICall({
        configuration: prepareDynamicAPICall,
        storageKey,
        eventDetails,
        commandCallback: commandCallback
          ? COMMANDS[commandCallback.type](commandContext, commandCallback)
          : undefined,
        onSuccess: onSuccess
          ? COMMANDS[onSuccess.type](commandContext, onSuccess)
          : undefined,
        onError: onError
          ? COMMANDS[onError.type](commandContext, onError)
          : undefined,
      }),
    );
  };
