export const PCP_SAGA_ACTION_TYPES = {
  LOAD_PCD: "LOAD_PCD",
  EXECUTE_API_CALL: "EXECUTE_API_CALL",
  SET_DATA_WITH_CALLBACK: "SET_DATA_WITH_CALLBACK",
};

export const UNINITIALIZED_PCD = "uninitialized";

export const INITIAL_PCD_STATE = {
  pcd: {
    version: UNINITIALIZED_PCD,
    root: {
      app: {
        protectedUI: { pages: [] },
        publicUI: { pages: [] },
      },
    },
    id: "",
    displayName: "",
    config: { apiBaseUrl: "", msal: { scopes: [], auth: { clientId: "" } } },
  },
  isLoading: true,
  componentContext: {},
  apiStorage: {},
};
