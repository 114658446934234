import { gql } from "urql";

// todo: Does not exist yet. Maybe needs to be adjusted as soon as BE parts are finished.
export const SEND_IOT_ACTION = gql`
  mutation ExecuteIoTAction(
    $command: String!
    $groupId: String!
    $targetEntities: [ID]!
    $parameters: [CommandParameterInput]
  ) {
    executeCommand(
      command: $command
      groupId: $groupId
      targetEntities: $targetEntities
      parameters: $parameters
    )
  }
`;
