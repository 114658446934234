import { Box, CircularProgress, Typography } from "@mui/material";
import { ILoadingPCP } from "../interfaces/ILoadingPCP";

export const LoadingIndicator = ({ loadingDesc }: ILoadingPCP) => {
  return (
    <Box
      sx={{
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CircularProgress
        sx={{ alignSelf: "center" }}
        color="secondary"
        size={64}
      />

      <Typography sx={{ mt: 2 }} alignSelf="center">
        {loadingDesc}
      </Typography>
    </Box>
  );
};
