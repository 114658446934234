import {
  useRouteError,
  isRouteErrorResponse,
  useNavigate,
} from "react-router-dom";
import { HttpStatusCode } from "axios";
import {
  FallbackView,
  IFallbackView,
} from "@paul/paul-components-collector-package";
import { NAVIGATION } from "../../../constants";
import { FALLBACK_PROPS, CENTER_SX } from "../constants";

/**
 * ErrorBoundary component that handles and displays errors for the route.
 * If the error appears, it renders a fallback view with appropriate actions.
 * Errors from loaders, actions or component rendering are caught here.
 * For more info: https://reactrouter.com/en/main/route/error-element#errorelement
 * @returns JSX.Element | null
 */
export const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  if (!isRouteErrorResponse(error)) {
    return null;
  }

  switch (error.status) {
    case HttpStatusCode.NotFound:
      return (
        <FallbackView
          sx={CENTER_SX}
          {...(FALLBACK_PROPS.notFoundUI as IFallbackView)}
          actionAreaItemsActions={{
            back: {
              onClick: (): void => navigate(NAVIGATION.default),
            },
          }}
        />
      );
    default:
      return (
        <FallbackView
          sx={CENTER_SX}
          {...(FALLBACK_PROPS.other as IFallbackView)}
          actionAreaItemsActions={{
            cancel: {
              onClick: (): void => navigate(NAVIGATION.default),
            },
            reload: {
              onClick: (): void => navigate(0),
            },
          }}
        />
      );
  }
};
