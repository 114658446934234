import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { getPathnameFromUrl } from "../helpers/getPathnameFromUrl";
import { NAVIGATION } from "../../../constants";

export const UnprotectedPaths = () => {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      isAuthenticated &&
      inProgress === InteractionStatus.None &&
      location.pathname === NAVIGATION.signInPathname
    ) {
      navigate(
        getPathnameFromUrl(instance.getConfiguration().auth.redirectUri),
      );
    }
  }, [isAuthenticated, inProgress]);

  return <Outlet />;
};
