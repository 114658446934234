import i18n from "i18next";
import cloneDeep from "lodash.clonedeep";
import {
  TRANSFORM_TYPE,
  TTransformData,
  IEventContextData,
} from "../../interfaces";
import { createDynamicData } from "../createDynamicData";
import { sortArray } from "./sortArray";
import { mapArray } from "./mapArray";
import { filterArray } from "./filterArray";
import { reduceArrayByKey } from "./reduceArrayByKey";
import { findInArray } from "./findInArray";

const transformArray = (
  toTransform: Record<string, unknown>[],
  transformationWay: TTransformData,
  eventContextData: IEventContextData,
) => {
  switch (transformationWay.type) {
    case TRANSFORM_TYPE.reduceArrayByKey:
      return reduceArrayByKey(toTransform, transformationWay, eventContextData);

    case TRANSFORM_TYPE.mapArray:
      return mapArray(toTransform, transformationWay, eventContextData);

    case TRANSFORM_TYPE.sortArray:
      return sortArray(toTransform, transformationWay);

    case TRANSFORM_TYPE.filterArray:
      return filterArray(toTransform, transformationWay);

    case TRANSFORM_TYPE.findInArray:
      return findInArray(toTransform, transformationWay);

    default:
      return toTransform;
  }
};

const transformString = (
  textToTransform: string,
  transformationWay: TTransformData,
) => {
  switch (transformationWay.type) {
    /* finds and returns the translation key for a given text in the current language */
    case TRANSFORM_TYPE.getTransKey: {
      const translations = i18n.store.data;
      const currentLanguage = i18n.language;
      const currentTranslations = translations[currentLanguage];
      if (!currentTranslations || !Object.keys(currentTranslations).length) {
        console.warn(
          `No translations found for the current language: "${currentLanguage}".`,
        );
        return textToTransform;
      }
      let translationsToSearchIn: Record<string, unknown> = currentTranslations;
      // if it includes different namespaces
      const hasNestedObjects = Object.values(currentTranslations).some(
        (value) => typeof value === "object" && value !== null,
      );
      if (hasNestedObjects) {
        // reduce to one object
        translationsToSearchIn = Object.values(currentTranslations).reduce<
          Record<string, unknown>
        >((acc, obj) => {
          if (typeof acc === "object" && typeof obj === "object") {
            return { ...acc, ...obj };
          }
          return acc;
        }, {});
      }

      const transKey = Object.keys(translationsToSearchIn).find(
        (key) => translationsToSearchIn[key] === textToTransform,
      );

      if (!transKey) {
        console.warn(
          `The translation key for "${textToTransform}" was not found in the current language "${currentLanguage}".`,
        );
        return "";
      }

      return transKey;
    }

    default:
      return textToTransform;
  }
};

const transform = (
  toTransform: unknown,
  transformationWay: TTransformData,
  eventContextData: IEventContextData,
) => {
  if (typeof toTransform === "string") {
    return transformString(toTransform, transformationWay);
  }

  const isArrayTransformation = Object.values(TRANSFORM_TYPE).includes(
    transformationWay.type,
  );

  if (!toTransform) {
    return isArrayTransformation ? [] : {};
  }

  if (isArrayTransformation && !Array.isArray(toTransform)) {
    console.warn(
      `The data you target for ${transformationWay.type} is not an array.`,
    );
    return [];
  }

  if (isArrayTransformation && Array.isArray(toTransform)) {
    return transformArray(toTransform, transformationWay, eventContextData);
  }

  return toTransform;
};

export const createTransformedData = (
  toTransform: unknown,
  eventContextData: IEventContextData,
  transformationWay?: TTransformData | TTransformData[],
) => {
  if (!transformationWay) {
    return toTransform;
  }

  if (Array.isArray(transformationWay)) {
    let transformedData = cloneDeep(toTransform);

    transformationWay.forEach((item) => {
      const { params, ...way } = item;
      const transformDefinition = createDynamicData(
        way,
        eventContextData,
        params,
        eventContextData.eventDetails,
      );

      transformedData = transform(
        transformedData,
        transformDefinition,
        eventContextData,
      );
    });

    return transformedData;
  }

  const { params, ...way } = transformationWay;

  const transformDefinition = createDynamicData(
    way,
    eventContextData,
    params,
    eventContextData.eventDetails,
  );

  return transform(toTransform, transformDefinition, eventContextData);
};
