import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPCD, ISetError } from "../../../interfaces";
import { INITIAL_PCD_STATE } from "../constants";
import { setStorageData } from "../helpers/setStorageData";

interface ISetPCD {
  readonly pcd: IPCD;
}

export interface ISetDynamicData {
  readonly storageKey: string;
  readonly data: unknown;
}

interface IPCPState {
  pcd: IPCD;
  componentContext: Record<string, unknown>;
  apiStorage: Record<string, unknown>;
  error?: unknown;
}

const initialState: IPCPState = INITIAL_PCD_STATE;

export const PCPSlice = createSlice({
  name: "pcp",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ISetError>) => {
      const { error } = action.payload;

      state.error = error;
    },
    setPCD: (state, action: PayloadAction<ISetPCD>) => {
      const { pcd } = action.payload;

      state.pcd = pcd;
    },
    setApiStorage: (state, { payload }: PayloadAction<ISetDynamicData>) => {
      const { storageKey, data } = payload;

      state.apiStorage = setStorageData(state.apiStorage, {
        storageKey,
        data,
      });
    },
    setComponentContext: (state, action: PayloadAction<ISetDynamicData>) => {
      const { storageKey, data } = action.payload;

      state.componentContext = setStorageData(state.componentContext, {
        storageKey,
        data,
      });
    },
  },
});

export const pcpActions = PCPSlice.actions;

export const pcpReducer = PCPSlice.reducer;
