import { setupCache } from "axios-cache-interceptor";
import axios from "axios";
import { cacheExchange, createClient, fetchExchange } from "urql";

const DEFAULT_GRAPHQL_ENDPOINT = "https://master-data-api.dev.paul-cloud.de";

/* attention: if response status is e.g. 201, response will not be cached because of default cache.cachePredicate config.
 * maybe there will be the need to adjust!
 * Default: { statusCheck: (status) => [200, 203, 300, 301, 302, 404, 405, 410, 414, 501].includes(status) }
 * (These default status codes follows RFC 7231)
 *
 * Important for us: POST requests are also not cached by default. Only GET. For POST that should be cached, you need
 * to set cache: true in config
 */
export const axiosClient = setupCache(axios);
export const graphQLClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT ?? DEFAULT_GRAPHQL_ENDPOINT,
  exchanges: [cacheExchange, fetchExchange],
});
