import { useCallback, useEffect, useMemo } from "react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { IPCD } from "../interfaces";
import { useAppDispatch } from "./hooks";
import { loginAction } from "../features/auth/store/AuthSagaActions";

export const useInitAuth = (pcd: IPCD | undefined, enabled: boolean) => {
  const dispatch = useAppDispatch();
  const msalInstance = useMemo(() => {
    if (!pcd?.config) {
      // eslint rule expects to return undefined, otherwise it complains:
      // because of 'consistent-return'
      return;
    }

    // eslint-disable-next-line consistent-return
    return new PublicClientApplication(pcd.config.msal);
  }, [pcd]);

  const initMSAL = useCallback(async () => {
    if (!msalInstance || !pcd) {
      return;
    }

    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    const usedAccount = accounts[0];

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const { account } = event.payload as AuthenticationResult;
        msalInstance.setActiveAccount(account);

        dispatch(
          loginAction({
            msalInstance,
            account,
            scopes: pcd.config.msal.scopes,
          }),
        );
      }
    });

    if (usedAccount) {
      dispatch(
        loginAction({
          msalInstance,
          account: usedAccount,
          scopes: pcd.config.msal.scopes,
        }),
      );
      msalInstance.setActiveAccount(usedAccount);
    }
  }, [msalInstance]);

  useEffect(() => {
    if (!pcd || !msalInstance || !enabled) {
      return;
    }

    initMSAL();
  }, [pcd, msalInstance, enabled]);

  return { msalInstance };
};
