import { IEventContextData, IMapArray } from "../../interfaces";
import { createDynamicData } from "../createDynamicData";

// Doc: https://actaqua.atlassian.net/wiki/x/AQDxj
export const mapArray = (
  toTransform: Record<string, unknown>[],
  transformationWay: IMapArray,
  eventContextData: IEventContextData,
) =>
  toTransform.map((item) =>
    createDynamicData(
      transformationWay.mapping,
      eventContextData,
      transformationWay.mappingParams,
      { ...item, ...eventContextData.eventDetails },
    ),
  );
