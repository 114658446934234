import {
  ICommandContext,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../interfaces";
import { isUpdateEntityCommand } from "./guards";
import { createDynamicData } from "../createDynamicData";
import { executeAPICall } from "../../features/pcp/store/PCPSagaActions";
import { createMDMApiCallStructure } from "../createMDMApiCallStructure";
import { getExtendedCommandContext } from "./getExtendedCommandContext";
import { COMMANDS } from "./Commands";

// todo: Remove as soon as we use gql only. Left in ServiceCommitmentEditHtg/PwhStatus (PCD)
// Doc: https://actaqua.atlassian.net/wiki/x/AYBOhQ
export const updateEntity: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isUpdateEntityCommand(command)) {
      console.warn(
        "Please check the updateEntity properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const { params, commandCallback, onError, onSuccess, ...updateDefinition } =
      command.commandProps;

    const prepareDynamicUpdate = createDynamicData(
      updateDefinition,
      extendedCommandContext,
      params,
      eventDetails,
    );

    const dynamicUpdate = createMDMApiCallStructure(prepareDynamicUpdate);

    commandContext.dispatch(
      executeAPICall({
        configuration: dynamicUpdate,
        storageKey: prepareDynamicUpdate.upsertStorageKeyByResponse,
        eventDetails,
        commandCallback: commandCallback
          ? COMMANDS[commandCallback.type](commandContext, commandCallback)
          : undefined,
        onSuccess: onSuccess
          ? COMMANDS[onSuccess.type](commandContext, onSuccess)
          : undefined,
        onError: onError
          ? COMMANDS[onError.type](commandContext, onError)
          : undefined,
      }),
    );
  };
