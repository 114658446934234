import { ICommandContext, TCommand } from "../../interfaces";
import { COMMANDS } from "./index";

export const getCommand = (
  command: TCommand,
  commandContext: ICommandContext,
) => {
  const { type } = command;

  if (!Object.hasOwn(COMMANDS, type)) {
    console.warn(`Command type ${type} not implemented.`);
    return () => {};
  }

  return COMMANDS[type](commandContext, command);
};
