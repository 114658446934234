import { CacheRequestConfig } from "axios-cache-interceptor";
import {
  IPrepareMDMApiStructure,
  IPrepareRelations,
  IRelation,
  MDM_ACTIONS,
} from "../interfaces";
import {
  CREATE_MDM_ENDPOINT,
  CREATE_MDM_METHOD,
  DEFAULT_MDM_LOCALE,
  UPDATE_MDM_ENDPOINT,
  UPDATE_MDM_METHOD,
  POST_FORM_ENDPOINT,
  CONTACT_FORM_TYPE,
} from "../constants";
import { IAttribute } from "../interfaces/IDynamicApiCall";

const createRelationshipStructure = (
  preparedRelations?: Record<string, IPrepareRelations>,
) => {
  const relationships: Record<string, IRelation[]> = {};

  Object.entries(preparedRelations ?? {}).forEach(([key, entry]) => {
    const {
      type,
      relationIds: possiblyNotAnArray,
      direction,
      previousEntity,
    } = entry;

    const relationIds = Array.isArray(possiblyNotAnArray)
      ? possiblyNotAnArray.slice()
      : [possiblyNotAnArray];

    // To delete relations, we need to set and additional action: "delete" flag inside the update call for this specific
    // part. To get the necessary data for it, we need to compare the original (previous) entity, with our update call.
    // Then we know which data needs to be deleted.
    const relationsToDelete = previousEntity?.relationships?.[key]?.filter(
      (prevRelation) => !relationIds.includes(prevRelation.relTo.id),
    );

    // Generate the items for relations we want to update/add to the entity
    relationships[key] = relationIds.map((id) => ({
      relTo: { id, type },
      properties: { direction, relationshipType: key },
    }));

    // Map of the entities we need to remove, an add the action: "delete" flag for them inside the update call.
    // Combined with the step before, the structure for the update call is complete.
    relationships[key] = relationships[key].concat(
      relationsToDelete?.map((toDelete) => ({
        action: MDM_ACTIONS.delete,
        ...toDelete,
      })) ?? [],
    );
  });

  return relationships;
};

const createAttributesStructure = (
  preparedAttributes?: Record<string, unknown>,
) => {
  const attributes: Record<string, IAttribute> = {};

  Object.entries(preparedAttributes ?? {}).forEach(([key, entry]) => {
    attributes[key] = {
      ...(entry === undefined || entry === null || entry === ""
        ? { action: "delete" }
        : {}), // If the entry is empty, we want to delete the attribute.
      values: [
        // todo: For now we only need the "de-DE" locale, but in the future we have to differentiate
        { value: entry, locale: DEFAULT_MDM_LOCALE, source: "internal" },
      ],
    };
  });

  return attributes;
};

// TODO: check if this needed after PF-807
// It can happen in dynamic form to have not rendered fields. So replace not found values e.g. ":invoceNumber" with empty "".
const replaceMatchingValuesWithEmptyString = (
  obj?: Record<string, unknown>,
): Record<string, unknown> => {
  if (!obj) {
    return {};
  }
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === `:${key}`) {
      newObj[key] = "";
    }
  });
  return newObj;
};

/**
 * This function creates MDM specific entity-request-configs. Unfortunately we need this, because of the complicated
 * MDM data structure. Hopefully gets replaced in the future, if we have a simpler data structure in the backend then.
 *
 * @returns {Object} MDM adjusted request config for updating or creating entities (depending on existing id).
 * @param {Object} preparedData - This param defines the data, that should be processed to MDM.
 * */
export const createMDMApiCallStructure = (
  preparedData: IPrepareMDMApiStructure,
): CacheRequestConfig => {
  const {
    attributes: preparedAttributes,
    relations: preparedRelations,
    type: entityType,
    id: updateId,
  } = preparedData;
  let attributes;

  attributes = replaceMatchingValuesWithEmptyString(preparedAttributes);

  if (entityType === CONTACT_FORM_TYPE) {
    return {
      url: POST_FORM_ENDPOINT,
      method: CREATE_MDM_METHOD,
      data: {
        ...attributes,
      },
    };
  }

  const relationships = createRelationshipStructure(preparedRelations);
  attributes = createAttributesStructure(preparedAttributes);

  return {
    url: updateId ? UPDATE_MDM_ENDPOINT : CREATE_MDM_ENDPOINT,
    method: updateId ? UPDATE_MDM_METHOD : CREATE_MDM_METHOD,
    data: {
      type: entityType,
      id: updateId,
      data: { relationships, attributes },
    },
  };
};
