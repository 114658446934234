import { CONDITION_OPERATION } from "./ICondition";

/**
 * @param {urlSource} - Defines the value path to the desired value from url params. If router path was 'test/:personId'
 * then the path definition is { urlSource: { path: "personId" } } --> react-router removes colon ":" :personId gets personId
 * @param {eventSource} - Can be any key. Represents the placeholder to replace
 * @param {dataSource} - Can be any key. Represents the placeholder to replace
 */
// todo: We could combine these types and just use a type like type: "url", type: "storage", type: "event" -> Like in our commands.
export interface IParams {
  urlSource?: ISource;
  queryParamsSource?: ISource;
  eventSource?: ISource;
  dataSource?: ISource;
}

/**
 * @key {string} The key represents the placeholder that should get replaced with the value in the path
 * @param {string} path - Path is necessary, so the algorithm can dive into the structure of the given object
 * @param {unknown} defaultValue - Optional default value, if no value is found under the defined path, the defaultValue is taken
 */
export interface ISource {
  [key: string]: {
    path: string;
    transform?: TTransformData | TTransformData[];
    defaultValue?: unknown;
  };
}

export enum TRANSFORM_TYPE {
  reduceArrayByKey = "reduceArrayByKey",
  mapArray = "mapArray",
  sortArray = "sortArray",
  filterArray = "filterArray",
  findInArray = "findInArray",
  getTransKey = "getTransKey",
}

export interface ITransform {
  type: TRANSFORM_TYPE;
  params?: IParams;
}

export interface IReduceArrayByKey extends ITransform {
  type: TRANSFORM_TYPE.reduceArrayByKey;
  mappingPath: string;
  transformDataItem?: TTransformData;
  spreadDataItem?: boolean;
}

export interface IFilterArrayByValue extends ITransform {
  type: TRANSFORM_TYPE.filterArray;
  pathToValue: string;
  operation: CONDITION_OPERATION;
  compareWithValue: unknown;
}

export interface IMapArray extends ITransform {
  type: TRANSFORM_TYPE.mapArray;
  mapping: IMapping;
  mappingParams?: IParams;
}

export interface IFindInArray extends ITransform {
  type: TRANSFORM_TYPE.findInArray;
  comparisonPath: string;
  comparisonValue: unknown;
}

export interface ISortArray extends ITransform {
  type: TRANSFORM_TYPE.sortArray;
  comparisonPath: string;
}

export interface IGetTransKey extends ITransform {
  type: TRANSFORM_TYPE.getTransKey;
  path: string;
}

export interface IMapping {
  readonly [key: string]: string;
}

export interface IGenericData {
  [key: string]: unknown;
}

export type TTransformData =
  | IReduceArrayByKey
  | IMapArray
  | ISortArray
  | IGetTransKey
  | IFindInArray
  | IFilterArrayByValue;

/**
 * For internal use to distinguish inside the dynamic function the different algorithms depending on source-type
 * */
export enum SOURCES {
  event = "event",
  storage = "storage",
  url = "url",
  queryParams = "queryParams",
}
