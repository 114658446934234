import moment from "moment";
import "moment/min/locales";
import { useTranslation } from "react-i18next";

/* @info This function is used to get the short locale from the current locale
 */
const makeShortLocale = (locale: string) => {
  const shortLocaleLength = 2;
  return locale.slice(0, shortLocaleLength);
};

export const useMomentLocale = () => {
  const { i18n } = useTranslation();
  const { language = "" } = i18n;
  const momentLocale = makeShortLocale(language);
  moment.locale(momentLocale);

  return { reactMoment: moment, momentLocale };
};
