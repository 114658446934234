import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  IComponent,
  IConditionProps,
  IConditionDefinition,
} from "../interfaces";
import { evaluateConditionExpression } from "../helpers/evaluateConditionExpression";
import { useAppSelector } from "./hooks";
import {
  selectApiStorage,
  selectComponentContext,
} from "../features/pcp/store/PCPSelectors";

/**
 * Custom hook that calculates the condition data result based on the provided component and context.
 * @param component - The component object.
 * @returns An object 'nestedKey' (any wanted key) containing the calculated component condition data OR just the calculated component condition data as direct props.
 * See more details: https://actaqua.atlassian.net/wiki/x/GIC0j
 */
export const useConditions = (component: IComponent) => {
  const { conditionProps = {} } = component;
  const componentContext = useAppSelector(selectComponentContext);
  const apiStorage = useAppSelector(selectApiStorage);
  const context = { ...componentContext, ...apiStorage };
  const { t: translate } = useTranslation();

  // Function to process conditionProps when it's an object with nested keys
  const getConditionDataResultFromObject = (
    conditionPropsData: IConditionProps,
  ) =>
    Object.entries(conditionPropsData).reduce(
      (result: Record<string, unknown>, [key, conditions]) => {
        const newResult = { ...result };
        newResult[key] = (conditions as IConditionDefinition[]).reduce(
          (
            conditionResult: Record<string, unknown>,
            condition: IConditionDefinition,
          ) => {
            const { conditions: conditionConfig, dataToApply } = condition;
            const evaluationResult = evaluateConditionExpression(
              conditionConfig,
              context,
              translate,
            );

            const data = dataToApply[evaluationResult as string];

            return { ...conditionResult, ...data };
          },
          {},
        );
        return newResult;
      },
      {},
    );

  // Function to process conditionProps when it's an array
  const getConditionDataResultFromArray = (
    conditionPropsData: IConditionDefinition[],
  ) => {
    return conditionPropsData.reduce(
      (result: Record<string, unknown>, condition: IConditionDefinition) => {
        const { conditions: conditionConfig, dataToApply } = condition;
        const evaluationResult = evaluateConditionExpression(
          conditionConfig,
          context,
          translate,
        );

        const data = dataToApply[evaluationResult as string];

        return { ...result, ...data };
      },
      {},
    );
  };

  // Determine if conditionProps has a nested key
  const nestedKey =
    !Array.isArray(conditionProps) &&
    Object.keys(conditionProps).find(
      (key) =>
        typeof conditionProps[key] === "object" &&
        !Array.isArray(conditionProps[key]),
    );

  // Get the condition data result
  const conditionDataResult = useMemo(() => {
    if (Array.isArray(conditionProps)) {
      return getConditionDataResultFromArray(conditionProps);
    }
    if (nestedKey) {
      return getConditionDataResultFromObject(conditionProps[nestedKey]);
    }
    return getConditionDataResultFromObject(conditionProps);
  }, [conditionProps, context, translate]);

  // Construct the component condition props
  const componentConditionData = nestedKey
    ? { [nestedKey]: conditionDataResult }
    : conditionDataResult;

  return { componentConditionData };
};
