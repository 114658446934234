import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { IComponent } from "../../../interfaces";
import { checkNestedUrlParams } from "../helpers/checkNestedUrlParams";

const DEFAULT_URL_PROPS = undefined;
export const useControlByUrl = ({
  ...componentProps
}: IComponent): IComponent => {
  const id = componentProps.staticProps?.id as string | undefined;
  const [searchParams] = useSearchParams();

  const urlProps = useMemo(() => {
    try {
      if (!id) {
        return DEFAULT_URL_PROPS;
      }
      const stringifyParams = searchParams.get(id);
      if (!stringifyParams) {
        return DEFAULT_URL_PROPS;
      }
      const params = JSON.parse(stringifyParams);
      return checkNestedUrlParams(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error in urlProps parsing\n", error);
      return DEFAULT_URL_PROPS;
    }
  }, [searchParams, id]);

  return { urlProps, ...componentProps };
};
