import { CacheRequestConfig } from "axios-cache-interceptor";
import { DocumentNode } from "graphql";
import { IParams } from "./IParams";
import { TCommand, TExecuteCommand } from "./ICommands";
import { TGenericData } from "./IPCD";

/**
 * @param {Object} configuration - Request configuration object. See CacheRequestConfig from axios-cache-interceptor.
 * Can contain placeholders that are resolved in params (see following)
 * @param {Object} params - Optional object that defines how to resolve placeholders from configuration object with
 * dynamic data.
 * @param {string} storageKey - Optional string that defines if the returned data from the API should be stored and under
 * which path/key.
 *
 * example:
 * {
 *     "configuration": {
 *         "url": "/clients/entities/update",
 *         "method": "POST",
 *         "data": {
 *              "type": "Person",
 *              "id": "personId"
 *         },
 *     },
 *     "params": { "personId": { "source": "url" } }
 *     "storageKey": "selectedPerson"
 * }
 *
 * */
export interface IDynamicApiCall {
  configuration: CacheRequestConfig | IGraphQlRequest;
  storageKey?: string;
  onSuccess?: TCommand;
  onError?: TCommand;
  commandCallback?: TCommand;
  params?: IParams;
}

export interface IExecuteApiCall {
  configuration: CacheRequestConfig | IGraphQlRequest;
  storageKey?: string;
  onSuccess?: TExecuteCommand;
  onError?: TExecuteCommand;
  commandCallback?: TExecuteCommand;
  eventDetails?: TGenericData;
}

export interface IGraphQlRequest {
  node: DocumentNode;
  variables?: TGenericData;
  relationVariables?: Record<string, IPrepareGqlRelation>; // key must be the exact name of the gql variable
  headers?: HeadersInit;
}

export interface IPrepareGqlRelation {
  relationIds: string[];
  previousRelationIds?: string[];
}

// Reflects the necessary MDM API structure for updating entities
export enum MDM_ACTIONS {
  delete = "delete",
}

export interface ICreateEntity {
  type: string;
  data: IEntityData;
}

export interface IUpdateEntity extends ICreateEntity {
  id: string;
}

export interface IEntityData {
  relationships?: Record<string, IRelation[]>;
  // todo: attributes structure
  attributes?: Record<string, IAttribute>;
}

export interface IRelation {
  relTo: IRelationTo;
  properties: IRelationProperties;
  action?: MDM_ACTIONS.delete;
}

export interface IRelationTo {
  id: string;
  type: string;
}

export interface IRelationProperties {
  direction: string;
  relationshipType: string;
}

export interface IAttribute {
  values: IValue[];
}

interface IValue<T = unknown> {
  value: T;
  locale: string;
  // todo: what type of sources are there? More than internal?
  source: "internal";
}
