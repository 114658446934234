export type Environment =
  | "localhost"
  | "development"
  | "staging"
  | "production"
  | "unknown";

export const getEnvironment = (): Environment => {
  const environment = process.env.REACT_APP_ENV || "localhost";

  if (
    environment === "localhost" ||
    environment === "development" ||
    environment === "staging" ||
    environment === "production"
  ) {
    return environment;
  }

  return "unknown"; // 'unknown' for any unrecognized environment
};
