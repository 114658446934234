import { useEffect, useMemo, useRef } from "react";
import { useCommandContext } from "../../../hooks/useCommandContext";
import { TCommand } from "../../../interfaces";
import { getCommand } from "../../../helpers/commands";
import { ILoop } from "../interfaces";

interface IExecuteCommand {
  readonly command: TCommand;
  readonly commandConfig?: {
    readonly enabled: boolean;
    readonly loop?: ILoop;
  };
}

// Doc: https://actaqua.atlassian.net/wiki/x/AYCVlQ
export const ExecuteCommandComponent = ({
  command,
  commandConfig,
}: IExecuteCommand) => {
  const isGettingExecuted = useRef(false);
  const intervalRef: { current: NodeJS.Timer | undefined } = useRef(undefined);
  const commandContext = useCommandContext();
  const { enabled = true, loop } = commandConfig || {};

  const toExecute = useMemo(
    () => getCommand(command, commandContext),
    [command, commandContext],
  );

  const cleanUp = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
    isGettingExecuted.current = false;
  };

  useEffect(() => {
    // execute when enabled === true
    const checkCondition = () => {
      if (isGettingExecuted.current || !enabled) {
        return;
      }

      isGettingExecuted.current = true;

      if (loop) {
        toExecute({});

        intervalRef.current = setInterval(
          () => toExecute({}),
          loop.intervalInMs,
        );

        return;
      }

      toExecute({});
    };

    checkCondition();

    return () => cleanUp();
  }, [enabled, loop, toExecute]); // Destructure and use specific dependencies instead of the whole `commandConfig`

  return null;
};
