import { useMemo } from "react";
import { IComponent, IActionProps, TCommandSet, TCommand } from "../interfaces";
import { useCommandContext } from "./useCommandContext";
import { getCommand } from "../helpers/commands";

/**
 * @description
 * getActionProps will process pcd actionProps data and return in the same command structure instead of command descriptions
 * @example pcd
 *  actionProps: {
 *   actionColumns: {
 *     id: {
 *       onClick: {
 *         ...command,
 *       },
 *     },
 *   },
 *   actionMenu: [
 *     {
 *       onClick: {
 *         ...command,
 *       },
 *     },
 *   ],
 * }
 */
/**
 * @example return in props
 *  {
 *   actionColumns: {
 *     id: {
 *       onClick: eventDetails => {…},
 *     },
 *   },
 *   actionMenu: [
 *     {
 *       onClick: eventDetails => {…},
 *     },
 *   ],
 * }
 */

type IGetActionProps =
  | {
      [key: string]:
        | IGetActionProps
        | IGetActionProps[]
        | TCommandSet
        | TCommandSet[];
    }
  | TCommandSet;

export const useActionCommands = (component: IComponent) => {
  const commandContext = useCommandContext();
  const { actionProps = {} } = component;

  const getActionProps = (actionPropsData: IActionProps): IGetActionProps => {
    return Object.entries(actionPropsData).reduce(
      (
        accumulator: IGetActionProps,
        [currentActionPropKey, actionPropsOrCommand],
      ) => {
        const { type: commandType } = actionPropsOrCommand as IActionProps;

        const isCommand = commandType !== undefined;
        const isArrayOfActionProps =
          !isCommand && Array.isArray(actionPropsOrCommand);
        const isObjectOfActionProps =
          !isCommand &&
          actionPropsOrCommand !== null &&
          typeof actionPropsOrCommand === "object";

        if (isArrayOfActionProps) {
          accumulator[currentActionPropKey] = actionPropsOrCommand.map(
            (commands) => {
              return getActionProps(commands as IActionProps);
            },
          );
          return accumulator;
        }

        if (isObjectOfActionProps) {
          accumulator[currentActionPropKey] = getActionProps(
            actionPropsOrCommand as IActionProps,
          );
          return accumulator;
        }

        if (isCommand) {
          const commandProps = actionPropsOrCommand as TCommand;
          accumulator[currentActionPropKey] = getCommand(
            commandProps,
            commandContext,
          );

          return accumulator;
        }

        return accumulator;
      },
      {},
    );
  };

  const componentActionCommands = useMemo(
    () => getActionProps(actionProps),
    [commandContext.urlParams],
  );

  return { componentActionCommands };
};
