import { store } from "../../store/store";
import { initialUserContext } from "../../constants";
import { ICommandContext } from "../../interfaces";

export const getStorage = () => {
  const data = store.getState();

  return {
    apiStorage: data.pcp.apiStorage,
    componentContext: data.pcp.componentContext,
    userContext: {
      accountInfo: data.auth.account ?? initialUserContext,
      accessToken: data.auth.accessToken,
    },
  };
};

export const getExtendedCommandContext = (commandContext: ICommandContext) => {
  const storages = getStorage();

  return {
    ...commandContext,
    apiStorage: storages.apiStorage,
    componentContext: storages.componentContext,
    userContext: storages.userContext,
  };
};
