import { v7 as uuidv7 } from "uuid";
import {
  ICommandContext,
  TCommand,
  TGenericData,
  TPrepareCommand,
} from "../../../interfaces";
import { getExtendedCommandContext } from "../getExtendedCommandContext";
import { isSendIotActionCommand } from "../guards";
import { createDynamicData } from "../../createDynamicData";
import { pcpActions } from "../../../features/pcp/store/PCPSlice";
import { executeAPICall } from "../../../features/pcp/store/PCPSagaActions";
import { COMMANDS } from "../Commands";
import { SEND_IOT_ACTION } from "./mutation";

const LATEST_GROUP_ID = "latestIotGroupId";

// Doc: https://actaqua.atlassian.net/wiki/x/AoCJlQ
export const sendIotAction: TPrepareCommand =
  (commandContext: ICommandContext, command: TCommand) =>
  (eventDetails: TGenericData) => {
    if (!isSendIotActionCommand(command)) {
      console.warn(
        "Please check the sendIotAction properties. Something seems to be wrong.",
      );

      return;
    }

    const extendedCommandContext = getExtendedCommandContext(commandContext);
    const {
      params,
      commandCallback,
      onError,
      onSuccess,
      upsertStorageKeyByResponse,
      ...iotActionProps
    } = command.commandProps;

    let variables = createDynamicData(
      iotActionProps,
      extendedCommandContext,
      params,
      eventDetails,
    );

    if (variables.parameters) {
      const stringifiedParams = variables.parameters?.map((param) => ({
        ...param,
        value: param.value.toString(),
      }));

      variables = { ...variables, parameters: stringifiedParams };
    }

    const groupId = uuidv7();

    const configuration = {
      node: SEND_IOT_ACTION,
      variables: { groupId, ...variables },
    };

    commandContext.dispatch(
      pcpActions.setApiStorage({
        storageKey: LATEST_GROUP_ID,
        data: groupId,
      }),
    );

    commandContext.dispatch(
      executeAPICall({
        configuration,
        storageKey: upsertStorageKeyByResponse,
        eventDetails,
        commandCallback: commandCallback
          ? COMMANDS[commandCallback.type](commandContext, commandCallback)
          : undefined,
        onSuccess: onSuccess
          ? COMMANDS[onSuccess.type](commandContext, onSuccess)
          : undefined,
        onError: onError
          ? COMMANDS[onError.type](commandContext, onError)
          : undefined,
      }),
    );
  };
