import { useMemo } from "react";
import { ComponentsMap } from "@paul/paul-components-collector-package";
import { ListOfMapComponents } from "@paul/paul-maps-package";
import { createComponent } from "../../processing-pcp";
import { usePCP } from "../../../hooks";
import { InternalComponentMap } from "../../InternalComponentMap";
import { IComponentRenderer } from "../interfaces/IComponentRenderer";
import { useControlByUrl } from "../../urlManager";

export const ComponentRenderer = (props: IComponentRenderer) => {
  const { pcdComponent, children } = props;
  const mergedReactComponentMap = useMemo(
    () => ({
      ...InternalComponentMap,
      ...ComponentsMap,
      ...ListOfMapComponents,
    }),
    [],
  );
  const updatedPcdComponent = useControlByUrl(pcdComponent);
  const Component = createComponent(
    updatedPcdComponent,
    mergedReactComponentMap,
  );
  const { data: dynamicProps } = usePCP(updatedPcdComponent);

  return <Component {...dynamicProps}>{children}</Component>;
};
