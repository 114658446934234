import { getDataByPath } from "@paul/paul-components-collector-package";
import {
  type IConditionValue,
  type TOperand,
  type IConditionBody,
  CONDITION_OPERATION,
} from "../interfaces";
import { doesInclude } from "./evaluationHelper";

/**
 * Evaluates a condition expression and returns a boolean value.
 * The function is recursive, as it calls itself when evaluating the operands. This allows it to handle nested condition expressions.
 * @param params - The condition expression to evaluate.
 * @returns The result of the condition evaluation.
 */
export const evaluateConditionExpression = (
  params: TOperand,
  context: Record<string, unknown>,
  translate: (key: string) => string,
): boolean | unknown => {
  const {
    operation,
    operands = [],
    pathToIncludesValue = "",
  } = params as IConditionBody;
  const {
    value,
    dataSource = {},
    translatable = false,
  } = params as IConditionValue;

  const translatedValue =
    typeof value === "string" && translatable ? translate(value) : value;

  const dataValue =
    value !== undefined
      ? translatedValue
      : dataSource?.value?.path &&
        getDataByPath(dataSource?.value?.path, context);

  switch (operation) {
    case CONDITION_OPERATION.EQUAL:
      return (
        evaluateConditionExpression(operands[0], context, translate) ===
        evaluateConditionExpression(operands[1], context, translate)
      );
    case CONDITION_OPERATION.NOT_EQUAL:
      return (
        evaluateConditionExpression(operands[0], context, translate) !==
        evaluateConditionExpression(operands[1], context, translate)
      );
    case CONDITION_OPERATION.INCLUDES: {
      const data = evaluateConditionExpression(operands[0], context, translate);
      const shouldInclude = evaluateConditionExpression(
        operands[1],
        context,
        translate,
      );

      if (Array.isArray(data)) {
        return doesInclude(data, pathToIncludesValue, shouldInclude);
      }
      if (typeof data === "object") {
        return (
          getDataByPath(
            pathToIncludesValue,
            data as Record<string, unknown>,
          ) === shouldInclude
        );
      }
      if (typeof data === "string" && typeof shouldInclude === "string") {
        return data.includes(shouldInclude);
      }

      return false;
    }
    case CONDITION_OPERATION.NOT_INCLUDES: {
      const data = evaluateConditionExpression(operands[0], context, translate);
      const shouldInclude = evaluateConditionExpression(
        operands[1],
        context,
        translate,
      );

      if (Array.isArray(data)) {
        const includes = doesInclude(data, pathToIncludesValue, shouldInclude);

        return !includes;
      }
      if (typeof data === "object") {
        const includes =
          getDataByPath(
            pathToIncludesValue,
            data as Record<string, unknown>,
          ) === shouldInclude;

        return !includes;
      }
      if (typeof data === "string" && typeof shouldInclude === "string") {
        const includes = data.includes(shouldInclude);

        return !includes;
      }

      return false;
    }
    case CONDITION_OPERATION.AND:
      return operands.every((operand: TOperand) =>
        Boolean(evaluateConditionExpression(operand, context, translate)),
      );
    case CONDITION_OPERATION.OR:
      return operands.some((operand: TOperand) =>
        Boolean(evaluateConditionExpression(operand, context, translate)),
      );
    case CONDITION_OPERATION.GREATER:
      return (
        Number(evaluateConditionExpression(operands[0], context, translate)) >
        Number(evaluateConditionExpression(operands[1], context, translate))
      );
    case CONDITION_OPERATION.LESS:
      return (
        Number(evaluateConditionExpression(operands[0], context, translate)) <
        Number(evaluateConditionExpression(operands[1], context, translate))
      );
    case CONDITION_OPERATION.GREATER_OR_EQUAL:
      return (
        Number(evaluateConditionExpression(operands[0], context, translate)) >=
        Number(evaluateConditionExpression(operands[1], context, translate))
      );

    case CONDITION_OPERATION.LESS_OR_EQUAL:
      return (
        Number(evaluateConditionExpression(operands[0], context, translate)) <=
        Number(evaluateConditionExpression(operands[1], context, translate))
      );
    default:
      return dataValue;
  }
};
