import { ReactNode } from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { useMomentLocale } from "./useMomentLocale";

interface ILocalizedProvider {
  children: ReactNode;
}
export const LocalizedProvider = ({ children }: ILocalizedProvider) => {
  const { momentLocale } = useMomentLocale();

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={momentLocale}
    >
      {children}
    </MuiLocalizationProvider>
  );
};
