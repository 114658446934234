import { put, takeLatest, call, takeEvery, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { pcpActions } from "./PCPSlice";
import { PCP_SAGA_ACTION_TYPES } from "../constants";
import { getPCD } from "../helpers/getPCD";
import {
  IExecuteApiCall,
  IPCD,
  TGenericData,
  ISetDataWithCallback,
} from "../../../interfaces";
import { ILoadPCD } from "../interfaces/ILoadPCD";
import { callAPI } from "../helpers/callAPI";
import { callingFunctionConditionally } from "../helpers/callingFunctionConditionally";
import { selectToken } from "../../auth/store/AuthSelectors";

function* loadInitialPCD({ payload }: PayloadAction<ILoadPCD>) {
  try {
    const pcd: IPCD = yield call(getPCD, payload);
    yield put(pcpActions.setPCD({ pcd }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* executeAPICall({ payload }: PayloadAction<IExecuteApiCall>) {
  const {
    storageKey = "",
    onSuccess,
    onError,
    commandCallback,
    configuration,
    eventDetails,
  } = payload;
  try {
    const token: string = yield select(selectToken);
    const { data } = yield call(callAPI, configuration, token);
    yield put(
      pcpActions.setApiStorage({
        storageKey,
        data,
      }),
    );

    yield call(
      callingFunctionConditionally,
      Boolean(onSuccess),
      data,
      onSuccess,
    );

    yield call(
      callingFunctionConditionally,
      Boolean(commandCallback),
      eventDetails ?? data,
      commandCallback,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(
      callingFunctionConditionally,
      Boolean(onError),
      configuration as TGenericData,
      onError,
    );
  }
}

function* setDataWithCallback({
  payload,
}: PayloadAction<ISetDataWithCallback>) {
  const {
    storageKey = "",
    storageData,
    commandCallback,
    eventDetails,
  } = payload;
  try {
    yield put(
      pcpActions.setComponentContext({
        storageKey,
        data: storageData,
      }),
    );

    yield call(
      callingFunctionConditionally,
      Boolean(commandCallback),
      eventDetails,
      commandCallback,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* PCPSagas() {
  yield takeLatest(PCP_SAGA_ACTION_TYPES.LOAD_PCD, loadInitialPCD);
  yield takeEvery(PCP_SAGA_ACTION_TYPES.EXECUTE_API_CALL, executeAPICall);
  yield takeEvery(
    PCP_SAGA_ACTION_TYPES.SET_DATA_WITH_CALLBACK,
    setDataWithCallback,
  );
}
