import {
  NAVIGATION,
  PATHNAME_SEPARATOR,
  PROTOCOL_SEPARATOR,
  PROTOCOL_URL_SPLIT_LENGTH,
} from "../../../constants";

export const getPathnameFromUrl = (url: string) => {
  const protocolSplit = url.split(PROTOCOL_SEPARATOR);

  if (protocolSplit.length !== PROTOCOL_URL_SPLIT_LENGTH) {
    return NAVIGATION.default;
  }

  const pathnameSplit = protocolSplit[1].split(PATHNAME_SEPARATOR);
  const namesWithoutBase = pathnameSplit.slice(1, pathnameSplit.length);

  const redirectTo = namesWithoutBase.join(PATHNAME_SEPARATOR);

  return redirectTo || NAVIGATION.default;
};
