export const checkNestedUrlParams = (obj: Record<string, unknown>) => {
  const newObj: Record<string, unknown> = { ...obj };
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      newObj[key] = checkNestedUrlParams(obj[key] as Record<string, unknown>);
      return;
    }
    if (typeof obj[key] !== "function") {
      newObj[key] = obj[key];
      return;
    }
    // eslint-disable-next-line no-console
    console.warn(
      "The function has been removed from the parameters for security reasons",
    );
  });
  return newObj;
};
