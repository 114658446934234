import { FALLBACK_STATUSES } from "@paul/paul-components-collector-package";

export const FALLBACK_PROPS = {
  notFoundUI: {
    status: FALLBACK_STATUSES.ERROR,
    title: {
      text: "404 - Page not found",
    },
    description: {
      text: "The site could not be found. Please check back your link or go back to start page",
    },
    buttons: [
      {
        id: "back",
        displayName: { text: "Back to start page" },
        size: "medium",
        variant: "contained",
        color: "primary" as const, // change here
      },
    ],
    // buttonActions is set directly as props to the component in the ErrorBoundary
  },
  other: {
    status: FALLBACK_STATUSES.ERROR,
    title: {
      text: "Sorry something went wrong",
    },
    description: {
      text: 'Return to the start with "Cancel" or click "Reload" to reload the page.',
    },
    buttons: [
      {
        id: "cancel",
        displayName: { text: "Cancel" },
        size: "medium",
        variant: "outlined",
        color: "primary",
      },
      {
        id: "reload",
        displayName: { text: "Reload" },
        size: "medium",
        variant: "contained",
        color: "primary",
        // now we can pass icon to a button inside ActionArea
        icon: {
          iconKey: "autorenew",
          color: "success.main",
        },
      },
    ],
    // buttonActions is set directly as props to the component in the ErrorBoundary
  },
};

export const CENTER_SX = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  m: "0 auto",
};
